
import { defineComponent, PropType } from "vue";
import TextInput from "@/components/TextInput.vue";
import { JsObject, ServerValidationErrors } from "@/types";
import { setAmount } from "@/modules/set-amount";
import Dropdown from "@/components/Dropdown.vue";

export interface CapitalistPayoutRequest {
  currency: string;
  amount: string;
  wallet: string;
}

export default defineComponent({
  props: {
    value: Object as PropType<CapitalistPayoutRequest>,
    errors: Object as PropType<ServerValidationErrors>,
  },
  components: {
    TextInput,
    Dropdown,
  },
  data() {
    return {
      currencies: [
        {
          id: "RUB",
          name: "RUB",
        },
        {
          id: "USD",
          name: "USD",
        },
        {
          id: "EUR",
          name: "EUR",
        },
      ],
    };
  },
  emits: ["update:value"],
  methods: {
    emitPatch(patch: JsObject) {
      this.$emit("update:value", {
        ...this.value,
        ...patch,
        amount: patch.amount ? setAmount(patch.amount) : this.value?.amount,
      });
    },
  },
});
