
import { defineComponent } from "vue";
import axios from "axios";
import moment from "moment";
import Preloader from "@/components/Preloader.vue";
import AppPage from "@/components/AppPage.vue";
import AccountInfo, { AccountInfoItem } from "@/components/AccountInfo.vue";
import DataTable from "@/components/DataTable.vue";
import { Pagination, Sorting } from "@/modules/types";
import Popup from "@/components/Popup.vue";
import TextInput from "@/components/TextInput.vue";
import Webmoney, { WebmoneyPayoutRequest } from "./Webmoney.vue";
import Capitalist, { CapitalistPayoutRequest } from "./Capitalist.vue";
import ZaleyCash, { ZaleyCashPayoutRequest } from "./ZaleyCash.vue";
import AlfaBank, { AlfaBankPayoutRequest } from "./AlfaBank.vue";
import WireTransfer, { WireTransferPayoutRequest } from "./WireTransfer.vue";
import Tinkoff, { TinkoffPayoutRequest } from "./Tinkoff.vue";
import SberBank, { SberBankPayoutRequest } from "./SberBank.vue";
import transferPopup from "./transferPopup.vue";

type PayoutRequest =
  | WebmoneyPayoutRequest
  | CapitalistPayoutRequest
  | ZaleyCashPayoutRequest
  | AlfaBankPayoutRequest
  | WireTransferPayoutRequest
  | TinkoffPayoutRequest
  | SberBankPayoutRequest;

interface IDataModel {
  loading: boolean;
  query: IQuery;
  rows: Row[];
  totalRows: number;
  infoRub: AccountInfoItem[];
  infoUsd: AccountInfoItem[];
  infoEur: AccountInfoItem[];
  selectedSystem: PayoutSystem | null;
  payoutRequest: PayoutRequest | null;
  errors: { [k: string]: string };
  isTransferPopupOpened: boolean;
}

interface Row {
  date: Date;
  transaction_id: number;
  amount: string;
  status: string;
  type_id: number;
  comment: string;
}

interface PayoutSystem {
  id: number;
  component: string;
  logo: string;
  title: string;
  emptyPayoutRequest: PayoutRequest;
  inDevelopment: boolean;
}

interface IQuery {
  pagination: null | Pagination;
  sorting: Sorting;
}

function getDefaultQuery(): IQuery {
  return {
    sorting: { column: "date", direction: "desc" },
    pagination: { limit: 10, offset: 0 },
  };
}

export default defineComponent({
  components: {
    Preloader,
    AppPage,
    AccountInfo,
    DataTable,
    Popup,
    TextInput,
    Webmoney,
    Capitalist,
    ZaleyCash,
    AlfaBank,
    WireTransfer,
    Tinkoff,
    SberBank,
    transferPopup,
  },
  created() {
    this.load();
  },
  data(): IDataModel {
    return {
      loading: false,
      query: getDefaultQuery(),
      rows: [],
      totalRows: 0,
      infoRub: [],
      infoUsd: [],
      infoEur: [],
      selectedSystem: null,
      payoutRequest: null,
      errors: {},
      isTransferPopupOpened: false,
    };
  },
  computed: {
    columns() {
      return [
        {
          id: "date",
          name: "Дата",
          selected: true,
          sortable: true,
        },
        {
          id: "id",
          name: "Номер транзакции",
          selected: true,
          sortable: false,
        },
        {
          id: "amount",
          name: "Сумма",
          selected: true,
          sortable: true,
          htmlClass: "finances__table--sum",
        },
        {
          id: "status",
          name: "Статус",
          selected: true,
          sortable: true,
        },
        {
          id: "comment",
          name: "Тип операции",
          selected: true,
          sortable: false,
        },
      ];
    },
    systems(): PayoutSystem[] {
      return [
        // {
        //   id: 1,
        //   component: "webmoney",
        //   logo: "img/finances/webmoney",
        //   title: "WebMoney",
        //   emptyPayoutRequest: {
        //     currency: "",
        //     amount: "",
        //     wallet: "",
        //   },
        //   inDevelopment: true,
        // },
        {
          id: 2,
          component: "capitalist",
          logo: "img/finances/capitalist",
          title: "Capitalist",
          emptyPayoutRequest: {
            currency: "",
            amount: "",
            wallet: "",
          },
          inDevelopment: false,
        },
        // {
        //   id: 4,
        //   component: "zaley-cash",
        //   logo: "img/finances/zeley-cash",
        //   title: "ZaleyCash",
        //   emptyPayoutRequest: {
        //     amount: 0,
        //     email: "",
        //   },
        //   inDevelopment: true,
        // },
        // {
        //   id: 5,
        //   component: "alfa-bank",
        //   logo: "img/finances/alfabank",
        //   title: "АльфаБанк",
        //   emptyPayoutRequest: {
        //     amount: 0,
        //     card_number: "",
        //     first_name: "",
        //     last_name: "",
        //   },
        //   inDevelopment: true,
        // },
        // {
        //   id: 6,
        //   component: "wire-transfer",
        //   logo: "img/finances/wire",
        //   title: "Wire Transfer",
        //   emptyPayoutRequest: {
        //     amount: 0,
        //     company_name: "",
        //     company_url: "",
        //     company_address: "",
        //     phone: "",
        //     email: "",
        //     contact_person: "",
        //     recipient_iban: "",
        //     bank_name: "",
        //     bank_address: "",
        //     account_number: "",
        //     swift_code: "",
        //   },
        //   inDevelopment: true,
        // },
        // {
        //   id: 7,
        //   component: "tinkoff",
        //   logo: "img/finances/tinkoff",
        //   title: "Тинькофф Банк",
        //   emptyPayoutRequest: {
        //     amount: 0,
        //     card_number: "",
        //     first_name: "",
        //     last_name: "",
        //   },
        //   inDevelopment: true,
        // },
        // {
        //   id: 8,
        //   component: "sber-bank",
        //   logo: "img/finances/sber",
        //   title: "Сбербанк",
        //   emptyPayoutRequest: {
        //     card_number: "",
        //     amount: 0,
        //     wallet: "",
        //     first_name: "",
        //     last_name: "",
        //   },
        //   inDevelopment: true,
        // },
      ];
    },
  },
  mounted() {
    this.$watch("query.sorting", this.load);
    this.$watch("query.pagination", this.load);
  },
  methods: {
    async load() {
      this.loading = true;
      this.rows = [];
      try {
        const [
          {
            data: { rows, totalRows },
          },
          { data: infoRub },
          { data: infoUsd },
          { data: infoEur },
        ] = await Promise.all([
          axios.get("/api/transactions", {
            params: { q: JSON.stringify(this.query) },
          }),
          axios.get("/api/profile/account-info", { params: { currency: "RUB" } }),
          axios.get("/api/profile/account-info", { params: { currency: "USD" } }),
          axios.get("/api/profile/account-info", { params: { currency: "EUR" } }),
        ]);
        this.rows = rows;
        this.totalRows = totalRows;
        this.infoRub = [
          { title: "Доступно", amount: infoRub.available, currency: "RUB" },
          { title: "В ожидании", amount: infoRub.pending, currency: "RUB" },
          { title: "Всего", amount: infoRub.total, currency: "RUB" },
          { title: "Выплачено", amount: infoRub.paid, currency: "RUB" },
        ];
        this.infoUsd = [
          { title: "Доступно", amount: infoUsd.available, currency: "USD" },
          { title: "В ожидании", amount: infoUsd.pending, currency: "USD" },
          { title: "Всего", amount: infoUsd.total, currency: "USD" },
          { title: "Выплачено", amount: infoUsd.paid, currency: "USD" },
        ];
        this.infoEur = [
          { title: "Доступно", amount: infoEur.available, currency: "EUR" },
          { title: "В ожидании", amount: infoEur.pending, currency: "EUR" },
          { title: "Всего", amount: infoEur.total, currency: "EUR" },
          { title: "Выплачено", amount: infoEur.paid, currency: "EUR" },
        ];
      } catch (e: any) {
        this.$router.push(`/error/${(e.response && e.response.status) || 500}`);
      }

      this.loading = false;
    },
    formatDate(d: string): string {
      return moment(d).format("DD.MM.YYYY");
    },
    openPayoutRequest(system: PayoutSystem) {
      this.selectedSystem = system;
      this.payoutRequest = { ...system.emptyPayoutRequest };
    },
    closePayoutRequest() {
      this.selectedSystem = null;
      this.payoutRequest = null;
      this.errors = {};
    },
    onCurrencyExchanged() {
      ($ as any).fancybox.close();
      this.load();
    },
    async submitPayoutRequest() {
      if (!this.payoutRequest || !this.selectedSystem) {
        return;
      }
      this.errors = {};

      try {
        await axios.post("/api/payouts", {
          amount: this.payoutRequest.amount,
          currency: this.payoutRequest.currency,
          system:
            this.payoutRequest.currency === "USD" &&
            this.selectedSystem.component === "capitalist"
              ? "capitalist-dollar"
              : this.payoutRequest.currency === "EUR"
              ? "capitalist-euro"
              : this.selectedSystem.component,
          credentials: this.payoutRequest,
        });
        ($ as any).fancybox.close();
        this.load();
      } catch (e: any) {
        const status = e.response?.status;
        if (status === 400) {
          this.errors = {
            amount: e.response.data.amount,
            currency: e.response.data.currency,
            ...e.response.data.credentials,
          };
        } else {
          this.$router.push(`/error/${status || 500}`);
        }
      }
    },
  },
});
