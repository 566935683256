import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "form__inner"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_input = _resolveComponent("text-input")!

  return (_ctx.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_text_input, {
          label: "Сумма выплаты в рублях",
          value: _ctx.value.amount,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (_ctx.emitPatch({ amount: $event }))),
          error: _ctx.errors?.amount,
          required: true,
          type: "number"
        }, null, 8, ["value", "error"]),
        _createVNode(_component_text_input, {
          label: "Номер кошелька WMZ",
          value: _ctx.value.wallet,
          "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => (_ctx.emitPatch({ wallet: $event }))),
          error: _ctx.errors?.wallet,
          required: true
        }, null, 8, ["value", "error"])
      ]))
    : _createCommentVNode("", true)
}