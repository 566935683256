import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "form__inner"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_input = _resolveComponent("text-input")!

  return (_ctx.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_text_input, {
          label: "Название компании-получателя",
          value: _ctx.value.company_name,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (_ctx.emitPatch({ company_name: $event }))),
          error: _ctx.errors?.company_name,
          required: true
        }, null, 8, ["value", "error"]),
        _createVNode(_component_text_input, {
          label: "URL компании-получателя",
          value: _ctx.value.company_url,
          "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => (_ctx.emitPatch({ company_url: $event }))),
          error: _ctx.errors?.company_url
        }, null, 8, ["value", "error"]),
        _createVNode(_component_text_input, {
          label: "Юридический адрес компании-получателя",
          value: _ctx.value.company_address,
          "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => (_ctx.emitPatch({ company_address: $event }))),
          error: _ctx.errors?.company_address,
          required: true
        }, null, 8, ["value", "error"]),
        _createVNode(_component_text_input, {
          label: "Телефон",
          value: _ctx.value.phone,
          "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => (_ctx.emitPatch({ phone: $event }))),
          error: _ctx.errors?.phone,
          type: "number"
        }, null, 8, ["value", "error"]),
        _createVNode(_component_text_input, {
          label: "Email",
          value: _ctx.value.email,
          "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => (_ctx.emitPatch({ email: $event }))),
          error: _ctx.errors?.email
        }, null, 8, ["value", "error"]),
        _createVNode(_component_text_input, {
          label: "Контактное лицо",
          value: _ctx.value.contact_person,
          "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => (_ctx.emitPatch({ contact_person: $event }))),
          error: _ctx.errors?.contact_person
        }, null, 8, ["value", "error"]),
        _createVNode(_component_text_input, {
          label: "Счет получателя (IBAN)",
          value: _ctx.value.recipient_iban,
          "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => (_ctx.emitPatch({ recipient_iban: $event }))),
          error: _ctx.errors?.recipient_iban,
          required: true
        }, null, 8, ["value", "error"]),
        _createVNode(_component_text_input, {
          label: "Название банка",
          value: _ctx.value.bank_name,
          "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => (_ctx.emitPatch({ bank_name: $event }))),
          error: _ctx.errors?.bank_name,
          required: true
        }, null, 8, ["value", "error"]),
        _createVNode(_component_text_input, {
          label: "Юридический адрес банка",
          value: _ctx.value.bank_address,
          "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => (_ctx.emitPatch({ bank_address: $event }))),
          error: _ctx.errors?.bank_address,
          required: true
        }, null, 8, ["value", "error"]),
        _createVNode(_component_text_input, {
          label: "Номер счета",
          value: _ctx.value.account_number,
          "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => (_ctx.emitPatch({ account_number: $event }))),
          error: _ctx.errors?.account_number
        }, null, 8, ["value", "error"]),
        _createVNode(_component_text_input, {
          label: "Swift Code",
          value: _ctx.value.swift_code,
          "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => (_ctx.emitPatch({ swift_code: $event }))),
          error: _ctx.errors?.swift_code,
          required: true
        }, null, 8, ["value", "error"])
      ]))
    : _createCommentVNode("", true)
}