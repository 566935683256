
import { defineComponent, PropType } from "vue";
import TextInput from "@/components/TextInput.vue";
import { JsObject, ServerValidationErrors } from "@/types";

export interface AlfaBankPayoutRequest {
  currency: string;
  amount: number;
  card_number: string;
  first_name: string;
  last_name: string;
}

export default defineComponent({
  props: {
    value: Object as PropType<AlfaBankPayoutRequest>,
    errors: Object as PropType<ServerValidationErrors>,
  },
  components: {
    TextInput,
  },
  emits: ["update:value"],
  methods: {
    emitPatch(patch: JsObject) {
      this.$emit("update:value", { ...this.value, ...patch });
    },
  },
});
