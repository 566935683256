
import { defineComponent, PropType } from "vue";
import TextInput from "@/components/TextInput.vue";
import { JsObject, ServerValidationErrors } from "@/types";

export interface WireTransferPayoutRequest {
  currency: string;
  amount: number;
  company_name: string;
  company_url: string;
  company_address: string;
  phone: string;
  email: string;
  contact_person: string;
  recipient_iban: string;
  bank_name: string;
  bank_address: string;
  account_number: string;
  swift_code: string;
}

export default defineComponent({
  props: {
    value: Object as PropType<WireTransferPayoutRequest>,
    errors: Object as PropType<ServerValidationErrors>,
  },
  components: {
    TextInput,
  },
  emits: ["update:value"],
  methods: {
    emitPatch(patch: JsObject) {
      this.$emit("update:value", { ...this.value, ...patch });
    },
  },
});
