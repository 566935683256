
import { defineComponent, PropType } from "vue";
import TextInput from "@/components/TextInput.vue";
import { JsObject, ServerValidationErrors } from "@/types";

export interface WebmoneyPayoutRequest {
  currency: string;
  amount: string;
  wallet: string;
}

export default defineComponent({
  props: {
    value: Object as PropType<WebmoneyPayoutRequest>,
    errors: Object as PropType<ServerValidationErrors>,
  },
  components: {
    TextInput,
  },
  emits: ["update:value"],
  methods: {
    emitPatch(patch: JsObject) {
      this.$emit("update:value", { ...this.value, ...patch });
    },
  },
});
