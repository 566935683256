
import { defineComponent, PropType } from "@vue/runtime-core";

export interface AccountInfoItem {
  title: string;
  amount: number;
  currency: string;
}

export default defineComponent({
  props: {
    value: Array as PropType<AccountInfoItem[]>,
    htmlClass: String,
  },
});
