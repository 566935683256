
import { defineComponent } from "vue";
import Popup from "@/components/Popup.vue";
import Dropdown from "@/components/Dropdown.vue";
import TextInput from "@/components/TextInput.vue";
import { Option } from "@/modules/types";
import axios from "axios";

type Rate = {
  id: string;
  low?: number | null;
  high?: number | null;
};

const RateData: Rate = {
  id: "USD",
  low: null,
  high: null,
};

export default defineComponent({
  components: {
    Popup,
    Dropdown,
    TextInput,
  },
  data() {
    return {
      transferCurrencyData: {
        id: 1,
        currency: "RUB",
      },
      receiveCurrencyData: {
        id: 2,
        currency: "USD",
      },
      transferAmount: 0,
      receiveAmount: 0,
      low_rate: 0,
      high_rate: 0,
      currencies: [] as Array<Option>,
      flags: [
        {
          currency: "RUB",
          flag: "RU",
        },
        {
          currency: "USD",
          flag: "USA",
        },
      ],
      errors: {} as { [key: string]: string | null },
    };
  },
  async created() {
    await this.getRates();
  },
  watch: {
    transferCurrencyData() {
      this.transferAmount = 0;
    },
  },
  computed: {
    transferToCurrencies() {
      return this.currencies.filter((c) => c.name !== "RUB");
    },
  },
  methods: {
    truncated(num: number) {
      return Math.trunc(num * 100) / 100;
    },
    getReceiveValue() {
      if (this.currencies && this.currencies.length) {
        switch (this.transferCurrencyData.currency) {
          case "EUR":
            this.receiveAmount = this.truncated(
              this.transferAmount * this.low_rate
            );
            break;
          case "RUB":
            this.receiveAmount = this.truncated(
              this.transferAmount / this.high_rate
            );
            break;
          case "USD":
            this.receiveAmount = this.truncated(
              this.transferAmount / this.high_rate
            );
            break;
        }

        return this.receiveAmount;
      }
    },
    getCountryNaming(currency: string) {
      return this.flags.find((f) => f.currency === currency)?.flag;
    },
    async updateCurrency(id: number, type: string) {
      let selected = this.currencies.find((c) => c.id === id);
      let other = this.transferToCurrencies.filter((c) => c.id !== id)[0];

      if (type === "receive") {
        this.receiveCurrencyData = {
          id: selected?.id as number,
          currency: selected?.name as string,
        };
      }

      if (type === "transfer") {
        this.transferCurrencyData = {
          id: selected?.id as number,
          currency: selected?.name as string,
        };
      }

      if (selected?.id === this.receiveCurrencyData.id) {
        this.receiveCurrencyData = {
          id: other?.id as number,
          currency: other?.name,
        };
      }

      if (selected?.id === this.transferCurrencyData.id) {
        this.transferCurrencyData = {
          id: selected?.id as number,
          currency: selected?.name,
        };
      }

      this.getRates();
    },
    async getRates() {
      const [{ data: rate }, { data: currencies }] = await Promise.all([
        await axios.get("/api/currencies/rate", {
          params: {
            from: this.transferCurrencyData.currency,
            to: this.receiveCurrencyData.currency,
          },
        }),
        await axios.get("/api/currencies"),
      ]);

      this.low_rate = rate.low_rate;
      this.high_rate = rate.high_rate;

      this.currencies = currencies;
    },
    async apply() {
      const req = {
        transferAmount: Number(this.transferAmount),
        transferCurrency: this.transferCurrencyData.currency,
        receiveAmount: Number(this.receiveAmount),
        receiveCurrency: this.receiveCurrencyData.currency,
      };
      try {
        await axios.post("/api/currencies/exchange", req);
        this.errors = {};
        this.$emit("apply");
      } catch (e) {
        const status = e.response && e.response.status;
        if (status === 400) {
          this.errors = e.response.data;
        } else {
          this.$router.push(`/error/${status || 500}`);
        }
      }
    },
  },
  emits: ["close", "apply"],
});
